import { default as Schema } from 'async-validator'

import { Title } from 'components'
import { Button, Toast } from 'antd-mobile'

export default function ({ navigator }) {
  const validator = React.useMemo(() => {
    return new Schema({
      order: {
        type: 'string',
        required: true,
        message: '请填写订单号',
      },
      phone: {
        type: 'string',
        required: true,
        message: '请填写手机号后四位',
        validator: (_, value) => value?.length == 4,
      },
    })
  }, [])

  const [ data, dispatch ] = React.useState({
    order: null,
    phone: null,
  })

  return <div className='flex flex-col h-screen items-center justify-center px-6 bg-no-repeat bg-center bg-cover bg-[url("/images/bg.jpg")]'>
    <Title>永信木业</Title>

    <div className='bg-white bg-opacity-30 rounded p-4 mb-8 w-full'>
      <div className='text-[#673e2c] text-center text-xl font-bold mb-6 mt-4'>永信木业订单查询</div>

      <div>
        <div className='m-4'>
          <input
            placeholder='请输入订单号'
            className='px-4 py-2 outline-none w-full h-full placeholder-slate-300 border border-transparent focus:border-lime-400 rounded-full transition ease-in-out duration-300'
            value={data.order}
            onChange={e => dispatch({ ...data, order: e.target.value?.trim() })}
          />
        </div>

        <div className='m-4'>
          <input
            placeholder='请输入手机号后四位'
            className='px-4 py-2 outline-none w-full h-full placeholder-slate-300 border border-transparent focus:border-lime-400 rounded-full transition ease-in-out duration-300'
            value={data.phone}
            onChange={e => dispatch({ ...data, phone: e.target.value?.trim() })}
          />
        </div>
      </div>
    </div>

    <div className='px-4 w-full'>
      <Button
        block
        color='primary'
        fill='solid'
        shape='rounded'
        onClick={() => validator.validate(data).then(() => navigator.to(`/result/${data.order}/${data.phone}`)).catch(({ errors }) => Toast.show(R.head<any>(errors).message))}
      >
        查询
      </Button>
    </div>
  </div>
}
