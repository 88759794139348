import { useRef } from 'react'
import { useRequest } from 'ahooks'

import { Title } from 'components'
import { ErrorBlock, Button, Modal } from 'antd-mobile'

export default function ({ store, navigator }) {
  const [
      productionType
    , orderProductionStatus
  ] = React.useMemo(() => {
    return [
        store.config.getConstantEnums(`ProductionType`)
      , store.config.getConstantEnums(`OrderProductionStatus`)
    ]
  }, [])

  const res = useRequest<any, any>(() => store.externals.api.get(`/orders/production/pool/list`).then(result => {
    if (result == null) {
      return []
    }

    return Object.keys(result).map(key => {
      const [
          type
        , status ] = key.split('_')

      return {
        data: result[key],
        title: `${productionType[type]}${orderProductionStatus[status]}`
      }
    })
  }))

  return <div className='p-4 min-h-screen bg-gray-100 space-y-8'>
    {R.isEmpty(res.data) || res.data == null ? (
      <div className='bg-white p-4 rounded-sm'>
        <ErrorBlock
          status='empty'
          title='暂无数据'
          description='暂时没有相关的工作安排.'
        />
      </div>
    ) : res.data?.map(elm => {
      return <section className=''>
        <h3 className='text-base font-bold pb-2 pl-1'>{elm.title}</h3>

        <ul className='space-y-4'>
          {elm.data.map(data => {
            return <li className='p-4 bg-white rounded space-y-2 transition ease-in-out duration-100 active:bg-blue-100' onClick={() => navigator.to(`/detail/${data.id}`)}>
              <div className='space-y-1'>
                <div className='text-base text-gray-800'>订单号：{data.orderNumber}</div>
                <div className='text-sm text-gray-500'>下单时间：{data.createTime}</div>
                <div className='text-sm text-gray-500'>客户姓名：{data.customersName}</div>
                <div className='text-sm text-gray-500'>地址：{data.customersAddress}</div>
              </div>

              <div className='space-y-1'>
                <div className='text-base text-gray-800'>订单描述：</div>
                <div className='text-sm text-gray-500'>{data.remark}</div>
              </div>
            </li>
          })}
        </ul>
      </section>
    })}

    <div className='px-4'>
      <Button
        block
        color='primary'
        fill='solid'
        onClick={() => Modal.confirm({ title: '温馨提示', content:'您确定要退出登录吗？', onConfirm: () => Promise.resolve(store.identity.clear()).then(() => navigator.to('/login')) })}
      >
        退出登录
      </Button>
    </div>
  </div>
}
