import api from '@sar/http'
import useOss from '@sar/framework/hooks/useOss'

export default function useExternals() {
  const oss = useOss(`${APP_ENV.endpoint}/oss`)

  return {
    api,
    oss,
    nonce: () => Math.random().toString(36).slice(2),
    guid: () => 'xxxxxxxx-xxxx-4xxx-xxxx-xxxxxxxxxxxx'.replace(/x/g, () => (Math.random()*16|0).toString(16)),
  }
}
