import { useRef } from 'react'
import { useRequest } from 'ahooks'

import { Title } from 'components'
import { ImageUploader } from 'antd-mobile'

interface Props {
  max: any,
  store: any,
  value?: any,
  onChange?: any
}

export default function ({ store, max, value, onChange }: Props) {
  return <ImageUploader
    value={value}
    maxCount={max}
    multiple={max > 1}
    onChange={onChange}
    upload={file => store.oss.client.multipartUpload(`${store.externals.nonce()}.${file.name.split('.').pop()}`, file).then(res => ({ name: file.name, url: `//${store.oss.endpoint}/${res.name}` }))}
  />
}
