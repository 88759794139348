import { useRef } from 'react'
import { useRequest } from 'ahooks'

import { Title } from 'components'
import { DotLoading, ImageViewer, ErrorBlock } from 'antd-mobile'

export default function ({ store, params }) {
  const orderStatus = React.useMemo(() => {
    return store.config.getConstantValues('OrderStatus').filter(({ value }) => [ 'collection' ].includes(value) == false)
  }, [])

  const res = useRequest<any, any>(() => store.externals.api.get(`/orders/mobile`, {
    params: {
      number: params.id,
      customerPhone: params.phone,
    }
  }), {
    loadingDelay: 300,
  })

  const getStatus = React.useCallback((data: string, type?: 'delivery' | 'install') => {
    if (res.data == null) {
      return '-'
    }

    const { status } = res.data

    let [
        current
      , expected
    ] = [
        orderStatus.findIndex(e => e.value == data)
      , orderStatus.findIndex(e => e.value == status)
    ]

    if ('assembling' == data) {
      if ('assembling' != status && 'end' != status) {
        return '未开始'
      }

      return (
          type == 'delivery' ? (res.data.goodsDelivery?.sendPeople == null ? '进行中' : '已完成')
        : type == 'install' ? (res.data.goodsDelivery?.sendPeople == null ? '未开始' : res.data.goodsDelivery?.installPeople == null ? '进行中' : '已完成') : '未开始'
      )
    }

    return (
        current < expected ? '已完成'
      : current > expected ? '未开始' : '进行中'
    )
  }, [ res.data ])

  return <div className='h-screen bg-gray-100 p-4'>
    {res.loading ? (
      <div className='p-4 bg-white rounded text-lime-400'>
        <DotLoading color='currentColor'/> 加载中
      </div>
    ) : (
      res.data == null ? <div className='bg-white py-16 rounded'><ErrorBlock status='empty'/></div> : (
        <>
          <div className='p-4 bg-white rounded space-y-2'>
            <div className='pb-2 text-gray-700 border-b border-gray-100 font-bold'>订单信息</div>

            <div className='flex items-center justify-between'>
              <div className='space-y-2'>
                <div className='text-gray-500'>客户姓名：{res.data?.customerName}</div>
                <div className='text-gray-500'>联系电话：{res.data?.customerPhone}</div>
                <div className='text-gray-500'>跟单员：{res.data?.saleName}</div>
              </div>

              <div>
                {res.data?.fileManageList?.filter(e => e.type == 'splitQRCode')?.slice(0, 1)?.map(elm => {
                  return <img
                    id={elm.url}
                    src={elm.url}
                    className='object-cover h-16 w-16'
                    onClick={() => ImageViewer.Multi.show({ images: res.data.fileManageList.filter(e => [ 'splitQRCode' ].includes(e.type)).map(R.prop('url')) })}
                  />
                })}
              </div>
            </div>
          </div>

          <div className='mt-4 p-4 bg-white rounded space-y-2'>
            <div className='pb-2 text-gray-700 border-b border-gray-100 font-bold'>订单状态</div>

            <table className='w-full border-collapse border border-slate-300'>
              <thead>
                <tr>
                  <th className='border border-slate-300 text-center p-1 bg-gray-200'>工序</th>
                  <th className='border border-slate-300 text-center p-1 bg-gray-200'>状态</th>
                  <th className='border border-slate-300 text-center p-1 bg-gray-200'>详情</th>
                </tr>
              </thead>

              <tbody className='text-gray-500'>
                <tr>
                  <td className='border border-slate-300 text-center p-1'>设计</td>
                  <td className='border border-slate-300 text-center p-1'>{getStatus('design')}</td>
                  <td className='border border-slate-300 text-center p-1 text-lime-500 active:text-lime-200' onClick={() => ImageViewer.Multi.show({ images: res.data.fileManageList.filter(e => [ 'blueprint', 'measurement' ].includes(e.type)).map(R.prop('url')) })}>查看</td>
                </tr>

                <tr>
                  <td className='border border-slate-300 text-center p-1'>报价</td>
                  <td className='border border-slate-300 text-center p-1'>{getStatus('quotation')}</td>
                  <td className='border border-slate-300 text-center p-1 text-lime-500 active:text-lime-200' onClick={() => ImageViewer.Multi.show({ images: res.data.fileManageList.filter(e => [ 'receiptVoucher' ].includes(e.type)).map(R.prop('url')) })}>查看</td>
                </tr>

                <tr>
                  <td className='border border-slate-300 text-center p-1'>拆单</td>
                  <td className='border border-slate-300 text-center p-1'>{getStatus('splitOrder')}</td>
                  <td className='border border-slate-300 text-center p-1 text-lime-500 active:text-lime-200' onClick={() => ImageViewer.Multi.show({ images: res.data.fileManageList.filter(e => [ 'splitQRCode' ].includes(e.type)).map(R.prop('url')) })}>查看</td>
                </tr>

                <tr>
                  <td className='border border-slate-300 text-center p-1'>原材料下单</td>
                  <td className='border border-slate-300 text-center p-1'>{getStatus('purchase')}</td>
                  <td className='border border-slate-300 text-center p-1 text-lime-500 active:text-lime-200'></td>
                </tr>

                <tr>
                  <td className='border border-slate-300 text-center p-1'>生产</td>
                  <td className='border border-slate-300 text-center p-1'>{getStatus('production')}</td>
                  <td className='border border-slate-300 text-center p-1 text-lime-500 active:text-lime-200'></td>
                </tr>

                <tr>
                  <td className='border border-slate-300 text-center p-1'>配送</td>
                  <td className='border border-slate-300 text-center p-1'>{getStatus('assembling', 'delivery')}</td>
                  <td className='border border-slate-300 text-center p-1 text-lime-500 active:text-lime-200'></td>
                </tr>

                <tr>
                  <td className='border border-slate-300 text-center p-1'>安装</td>
                  <td className='border border-slate-300 text-center p-1'>{getStatus('assembling', 'install')}</td>
                  <td className='border border-slate-300 text-center p-1 text-lime-500 active:text-lime-200'></td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )
    )}
  </div>
}
