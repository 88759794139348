export default function authorize(request) {
  const uid = JSON.parse(localStorage.getItem(`${APP_ENV.db}:uid`))
  const token = JSON.parse(localStorage.getItem(`${APP_ENV.db}:token`))

  const params = R.call(
    R.compose(
      R.set(R.lensProp<any>('uid'), uid),
      R.set(R.lensProp<any>('token'), token),
      R.set(R.lensProp<any>('nonce'), Math.random().toString(36).slice(2)),
    ),
    request.params,
  )

  const headers = R.call(
    R.compose(
      R.set(R.lensProp<any>('uid'), uid),
      R.set(R.lensProp<any>('token'), token),
      R.set(R.lensProp<any>('nonce'), Math.random().toString(36).slice(2)),
    ),
    request.headers,
  )

  return Object.assign(request, {
    params: R.filter(e => e != null, params),
    headers: R.filter(e => e != null, headers),
  })
}
