export default {
    author: APP_ENV.author
  , api: APP_ENV.endpoint
  , title: APP_ENV.title
  , subtitle: APP_ENV.subtitle
  , hashcode: APP_ENV.hashcode
  , version: APP_ENV.version
  , prefix: APP_ENV.prefix
  , timestamp: APP_ENV.timestamp
}
