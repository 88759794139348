import { useRequest } from 'ahooks'

function fetchData() {
  return fetch(`${APP_ENV.endpoint}/oss`, {
    mode: 'cors',
    method: 'get',
  })
}

declare const OSS

export default function useOSS() {
  const res = useRequest(async () => fetchData().then(res => res.json()).then(R.prop<any>('data')).then(res => new OSS.Wrapper({
    secure: res.data.secure,
    bucket: res.data.bucket,
    region: res.data.region,
    stsToken: res.data.Credentials.securityToken,
    accessKeyId: res.data.Credentials.accessKeyId,
    accessKeySecret: res.data.Credentials.accessKeySecret,
  })), {
    cacheKey: '/oss/getOSS',
    cacheTime: -1,
    staleTime: -1,
    pollingInterval: 60 * 30 * 1000,
  })

  return {
    client: res.data,
    loading: res.loading,
    bucket: `${res.data?.options.bucket}`,
    endpoint: `${res.data?.options.bucket}.${res.data?.options.endpoint.host}`,
  }
}
