import { Toast } from 'antd-mobile'

function messgae ({ content, clearToken = false }) {
  Toast.show({
    content,
    afterClose: !clearToken ? null : () => [ localStorage.clear(), location.replace(`${APP_ENV.prefix ?? ''}/login`) ],
  })

  return Promise.resolve()
}

export default function notify(response) {
  const {
      data: result
    , code
    , message
    , status
  } = response

  if (code == 401) {
    return messgae({ content: '您当前的会话凭证已过期，为了安全，请重新登陆', clearToken: true }).then(() => Promise.reject(`identity has expired with ${code}.`))
  }

  else if (code != 200 || result.code != 200) {
    return messgae({ content: result?.data?.msg || result.error || result.msg }).then(() => Promise.reject(`system error with ${result?.data?.msg || result.error}.`))
  }

  else {
    return Promise.resolve(result.data)
  }
}
