export default {
  Boolean: [
    {
      name: 'false',
      value: 1,
      description: '否',
    },
    {
      name: 'true',
      value: 0,
      description: '是',
    },
  ],
  MenuType: [
    {
      name: 'folder',
      value: 1,
      description: '目录',
    },
    {
      name: 'menu',
      value: 2,
      description: '菜单',
    },
    {
      name: 'button',
      value: 3,
      description: '按钮',
    },
  ],
  CustomerSource: [
    {
      name: 'plate',
      value: 0,
      description: '板式工厂自营',
    },
    {
      name: 'log',
      value: 1,
      description: '原木工厂自营',
    },
    {
      name: 'online',
      value: 2,
      description: '线上运营中心',
    },
    {
      name: 'store',
      value: 3,
      description: '直营门店',
    },
    {
      name: 'sale',
      value: 4,
      description: '直营销售公司',
    },
    {
      name: 'design',
      value: 5,
      description: '直营设计公司',
    },
    {
      name: 'bpoint',
      value: 6,
      description: 'B端',
    },
    {
      name: 'cpoint',
      value: 7,
      description: 'C端',
    },
  ],
  CustomerStage: [
    {
      name: 'preliminary',
      value: 0,
      description: '初步意向',
    },
    {
      name: 'negotiating',
      value: 1,
      description: '正在洽谈',
    },
    {
      name: 'success',
      value: 2,
      description: '已转客户',
    },
    {
      name: 'close',
      value: 3,
      description: '已结束',
    },
  ],
  OrderType: [
    {
      value: 1,
      description: '板式工厂',
    },
    {
      value: 2,
      description: '原木工厂',
    },
    {
      value: 3,
      description: '板式和原木',
    },
    {
      value: 4,
      description: '外购定制门板类',
    },
    {
      value: 5,
      description: '外购软装成品类',
    },
    {
      value: 6,
      description: '外购定制门板类和外购软装成品类',
    },
    {
      value: 7,
      description: '板式加工',
    },
    {
      value: 8,
      description: '原木加工',
    },
  ],
  OrderStatus: [
    {
      value: 'design',
      description: '设计',
    },
    {
      value: 'quotation',
      description: '报价',
    },
    {
      value: 'collection',
      description: '收款',
    },
    {
      value: 'splitOrder',
      description: '拆单',
    },
    {
      value: 'purchase',
      description: '生产原材料下单',
    },
    {
      value: 'production',
      description: '生产',
    },
    {
      value: 'assembling',
      description: '装配',
    },
    {
      value: 'end',
      description: '已完成',
    },
  ],
  OrderProductionStatus: [
    {
      value: 'cutting',
      description: '开料',
    },
    {
      value: 'edgeBand',
      description: '封边',
    },
    {
      value: 'drilling',
      description: '打孔',
    },
    {
      value: 'sorting',
      description: '分拣',
    },
    {
      value: 'qualityCheck',
      description: '质检',
    },
    {
      value: 'cleaningPacking',
      description: '清洁打包',
    },
    {
      value: 'completionStorage',
      description: '完成入库',
    },
    {
      value: 'milling',
      description: '铣型',
    },
    {
      value: 'polishing',
      description: '打磨',
    },
    {
      value: 'painting',
      description: '喷漆',
    },
  ],
  FileType: [
    {
      value: 'blueprint',
      description: '下单图纸',
    },
    {
      value: 'measurement',
      description: '现场量尺照片',
    },
    {
      value: 'chatRecords',
      description: '聊天记录及电话录音',
    },
    {
      value: 'autograph',
      description: '客户签字图纸照片',
    },
    {
      value: 'receiptVoucher',
      description: '收款凭证',
    },
    {
      value: 'materials',
      description: '领料单',
    },
    {
      value: 'purchase',
      description: '原材料采购凭证',
    },
    {
      value: 'present',
      description: '物料到厂凭证',
    },
    {
      value: 'cabinetBody',
      description: '标准柜体板料单',
    },
      {
      value: 'doorPanel',
      description: '标准门板料单',
    },
    {
      value: 'hardware',
      description: '标准五金料单',
    },
    {
      value: 'PTP',
      description: 'PTP',
    },
    {
      value: 'NC',
      description: 'NC',
    },
    {
      value: 'quotationFile',
      description: '报价文件存档',
    }
  ],
  QuotePriceStatus: [
    {
      value: 'yes',
      description: '已报价',
    },
    {
      value: 'no',
      description: '未报价',
    },
  ],
  SplitOrderStatus: [
    {
      value: 'yes',
      description: '已拆单',
    },
    {
      value: 'no',
      description: '未拆单',
    },
  ],
  ProductionType: [
    {
      value: 'wood',
      description: '原木',
    },
    {
      value: 'board',
      description: '板式',
    },
  ],
  ProductionProcessStatus: [
    {
      name: 'unstart',
      value: 'NS',
      description: '未开始',
    },
    {
      name: 'started',
      value: 'IS',
      description: '已开始',
    },
    {
      name: 'finished',
      value: 'IE',
      description: '已结束',
    },
  ],
  ConstantType: [
    {
      value: 'customerSource',
      description: '客户来源',
    },
  ]
}
