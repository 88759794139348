import { useStore } from '@sar/framework/hooks'
import { useLocation, useParams, useNavigate, useMatch } from 'react-router'

import { Title } from 'components'
import { Navigate } from 'react-router-dom'

export default (Target: React.FC<any>) => function (props) {
  const store = useStore()
  const location = useLocation()

  const params = useParams()
  const navigate = useNavigate()

  const isolate = R.call(
    R.cond([
      [() => useMatch(`/login`) != null, R.T],
      [() => useMatch(`/search`) != null, R.T],
      [() => useMatch(`/nomatch`) != null, R.T],
      [() => useMatch(`/result/:id/:phone`) != null, R.T],
    ])
  )

  const navigator = React.useMemo(() => {
    return {
      back: (stack = -1) => navigate(stack),
      to: (url) => navigate(url),
      replace: (url) => navigate(url, { replace: true }),
    }
  }, [])

  let target = <Target
    {...props}
    store={store}
    params={params}
    location={location}
    navigator={navigator}
  />

  if(isolate) {
    return target
  }

  if(store.identity.isLogined() == false){
    return <Navigate replace to='/login'/>
  }

  else {
    return target
  }
}
