import { v4 } from 'uuid'
import { sm2 } from 'sm-crypto'

import { useState } from 'react'
import { useRequest, clearCache } from 'ahooks'

import { Title } from 'components'
import { Form, Input, Button } from 'antd-mobile'

function toBase64 (buffer) {
  let binary = '',
      bytes = new Uint8Array(buffer);

  for (let len = bytes.byteLength, i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }

  return window.btoa(binary)
}

export default function({ store, navigator }) {
  const [ ref ] = Form.useForm()
  const [ uuid, dispatch ] = useState(v4())

  const img = useRequest(() => (
    fetch(`${store.config.env.api}/auth/code?uuid=${uuid}`)
      .then(response => response.arrayBuffer())
      .then(response => toBase64(response))
  ), {
    refreshDeps: [ uuid ]
  })

  const handleSubmit = useRequest(async data => {
   const result = await store.externals.api.post(`/auth/login?uuid=${data.uuid}`, {
      code: data.code,
      name: data.username,
      password: data.password,
    })

    store.identity.dispatch('user', result.emp)
    store.identity.dispatch('uid', result.emp.id)
    store.identity.dispatch('token', result.token)
  }, {
    manual: true,
  })

  return <div className='flex flex-col h-screen items-center justify-center px-6 bg-no-repeat bg-center bg-cover bg-[url("/images/bg.jpg")]'>
    <Title>永信木业</Title>

    <div className='bg-white bg-opacity-30 rounded p-4 mb-8'>
      <Form layout='horizontal' mode='card' form={ref} style={{ '--prefix-width': '4.5rem' }}>
        <Form.Header>
          <div className='text-[#673e2c] text-center text-3xl font-bold mb-6'>永信木业</div>
        </Form.Header>

        <Form.Item
          label='账号'
          name='username'
          rules={[ { required: true, message: '请输入登录账号' } ]}
        >
          <Input placeholder='登录账号' />
        </Form.Item>

        <Form.Item
          label='密码'
          name='password'
          rules={[ { required: true, message: '请输入登录密码' } ]}
        >
          <Input placeholder='登录密码' type='password' />
        </Form.Item>

        <Form.Item
          label='验证码'
          name='code'
          rules={[ { required: true, message: '请输入验证码' } ]}
          extra={
            <img
              title='点击刷新'
              className='h-6 cursor-pointer'
              onClick={() => dispatch(v4())}
              src={`data:image/jpg;base64,${img.data ? img.data : ''}`}
            />
          }
        >
          <Input placeholder='验证码' />
        </Form.Item>
      </Form>

      <div className='p-4 w-full'>
        <Button
          block
          color='primary'
          fill='solid'
          loading={handleSubmit.loading}
          onClick={() => ref.validateFields().then(res => handleSubmit.runAsync({ ...res, uuid, password: store.encrypt.encode(res.password) })).then(() => clearCache()).then(() => navigator.to(`/home`))}
        >
            登录
          </Button>
      </div>
    </div>
  </div>
}
