import { useStore } from '@sar/framework/hooks'
import { useRequest, clearCache } from 'ahooks'

export default (Target: React.FC<any>) => function () {
  const store = useStore()

  const getConstant = useRequest<any, any>(() => store.externals.api.get(`/constant/all`).then(R.groupBy(R.prop<any>('status'))).then(result => {
    Object.keys(result).forEach(type => {
      Object.assign(store.config.constants, {
        [ type.charAt(0).toLocaleUpperCase() + type.slice(1) ]: result[type].map(data => ({
          value: data.source,
          description: data.source,
        }))
      })
    })
  }), {
    ready: store.identity.isLogined(),
    staleTime: -1,
    cacheTime: -1,
    cacheKey: `/constant/all`,
  })

  if (!getConstant.loading) {
    return <Target
      store={store}
      refreshConstant={() => Promise.resolve(clearCache(`/constant/all`)).then(() => getConstant.runAsync())}
    />
  }

  else {
    return <div className='h-screen flex items-center justify-center'>
      加载中...
    </div>
  }
}
