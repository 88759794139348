import http from '@sar/http'
import engine from '@sar/framework'

import React from 'react'
import ReactDOM from 'react-dom/client'

import Login from 'views/login'
import Detail from 'views/detail'
import Result from 'views/result'

import Home from 'views/home'
import Search from 'views/search'
import Nomatch from 'views/nomatch'

import Layout from 'plugins/layout'
import Preload from 'plugins/preload'

import oss from 'hooks/oss'
import assets from 'hooks/assets'
import config from 'hooks/config'

import encrypt from 'hooks/encrypt'
import identity from 'hooks/identity'
import externals from 'hooks/externals'

import query from 'middlewares/query'
import author from 'middlewares/author'
import notification from 'middlewares/notification'

const APP = (
  engine
    .create()
    .set((app: engine) => app.usePlugin(Layout))
    .set((app: engine) => app.usePlugin(Preload))
    .set((app: engine) => app.usePrefix(APP_ENV.prefix))
    .set((app: engine) => app.useStore('oss', oss))
    .set((app: engine) => app.useStore('assets', assets))
    .set((app: engine) => app.useStore('config', config))
    .set((app: engine) => app.useStore('encrypt', encrypt))
    .set((app: engine) => app.useStore('identity', identity))
    .set((app: engine) => app.useStore('externals', externals))
    .set((app: engine) => app.useRedirect('/', '/home'))
    .set((app: engine) => app.useRedirect('/*', '/nomatch'))
    .set((app: engine) => app.useRoute('/result/:id/:phone',  Result))
    .set((app: engine) => app.useRoute('/detail/:id', Detail))
    .set((app: engine) => app.useRoute('/home', Home))
    .set((app: engine) => app.useRoute('/login', Login))
    .set((app: engine) => app.useRoute('/search',  Search))
    .set((app: engine) => app.useRoute('/nomatch',  Nomatch))
)

http.setting('base', APP_ENV.endpoint)
http.setting('adapter', APP_ENV.adapter)

http.useProxy('request', author)
http.useProxy('request', query)
http.useProxy('response', notification)

moment.locale('en-au')
moment.locale('zh-cn')

ReactDOM.createRoot(document.getElementById('root')).render(
  <APP.DOM store={APP}/>
)
