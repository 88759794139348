import { useRef } from 'react'
import { useRequest } from 'ahooks'

import { UploadImage } from 'components'
import { Form, Input, Button, TextArea } from 'antd-mobile'

export default function ({ store, params, navigator }) {
  const [ form ] = Form.useForm()
  const [ status ] = React.useMemo(() => [ store.config.getConstantEnums(`OrderProductionStatus`) ], [])

  useRequest(() => store.externals.api.get(`/orders/production/pool/${params.id}`).then(result => {
    form.setFieldsValue({
      qrcode: result.qrCode?.[0]?.url,
      files: result.file,
      remark: result.remark,
      previousRemark: result.previousRemark,
      number: result.orders.number,
      designerName: result.orders.designerName,
      splitterName: result.orders.splitterName,
      producerName: result.orders.producerName,
      createTime: result.file?.[0]?.createTime,
      status: status[result.pool.status.split('_').pop()],
      code: result.orders.customerPhone,
    })
  }))

  const handleSave = useRequest((data) => {
    return store.externals.api.put(`/orders/production/pool/${params.id}`, Object.assign(data, {
      files: data.files == null ? [] : data.files.map(R.pick([ 'fileName', 'url' ])),
    }))
  }, {
    manual: true,
    debounceWait: 300,
  })

  const handleSubmit = useRequest(() => {
    return store.externals.api.put(`/orders/production/pool/${params.id}`, {
      status: 'IE'
    })
  }, {
    manual: true
  })

  return <div className='bg-gray-100 min-h-screen pt-1'>
    <Form
      form={form}
      layout='vertical'
      mode='card'
      onValuesChange={e => handleSave.run({ ...e, files: e.files?.map(elm => ({ ...elm, fileName: elm.fileName || elm.name })) })}
    >
      <Form.Header>订单信息</Form.Header>

      <Form.Item
        label='订单号'
        name='number'
      >
        <Input readOnly placeholder='（暂无数据）'/>
      </Form.Item>

      <Form.Item
        label='查询码'
        name='code'
      >
        <Input readOnly placeholder='（暂无数据）'/>
      </Form.Item>

      <Form.Item
        label='设计师'
        name='designerName'
      >
        <Input readOnly placeholder='（暂无数据）'/>
      </Form.Item>

      <Form.Item
        label='拆单师'
        name='splitterName'
      >
        {React.createElement(({ value }) => {
          const qrcode = form.getFieldValue('qrcode')

          return <div>
            <Input readOnly placeholder='（暂无数据）' value={value}/>
            {qrcode == null ? null : <img src={qrcode} className='w-32 pt-2'/>}
          </div>
        })}
      </Form.Item>

      <Form.Item
        label='前工序备注'
        name='previousRemark'
      >
        {React.createElement(({ value }) => {
          return value ? value : '（暂无数据）'
        })}
      </Form.Item>

      <Form.Header>生产相关信息</Form.Header>

      <Form.Item
        label='负责人'
        name='producerName'
      >
        <Input readOnly placeholder='（暂无数据）'/>
      </Form.Item>

      <Form.Item
        label='工序'
        name='status'
      >
        <Input readOnly placeholder='（暂无数据）'/>
      </Form.Item>

      <Form.Item
        label='时间'
        name='createTime'
      >
        <Input readOnly placeholder='（暂无数据）'/>
      </Form.Item>

      <Form.Item
        label='图片'
        name='files'
      >
        <UploadImage max={10} store={store}/>
      </Form.Item>

      <Form.Item
        label='备注'
        name='remark'
      >
        <TextArea placeholder='请填写备注' rows={6} maxLength={1000} showCount />
      </Form.Item>
    </Form>

    <div className='px-4 py-2'>
      <Button
        block
        color='primary'
        fill='solid'
        loading={handleSubmit.loading}
        onClick={() => handleSubmit.runAsync().then(() => navigator.replace(`/home`))}
      >
        结束
      </Button>
    </div>
  </div>
}
